import React from "react"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import { useAuth0 } from "../../services/auth"
import { toast, ToastContainer } from "react-toastify"
import Seo from "../../components/seo"
import defaultImage from "../../images/default.png"
import { IoArrowBack } from "react-icons/io5"
import ProductSlider from "../../components/ProductSlider"
const UnicoProdotto = ({ data }) => {
  const { logo, name, mysqlId: id, products: unfilteredProducts } = data.mysqlPrograms
  const products = unfilteredProducts.filter(obj => obj.display === 1)
  const isBrowser = () => typeof window !== "undefined" && typeof window.navigation !== "undefined"
  const firstProduct = products.find((product) => !!product)
  const otherPrograms = data.allMysqlPrograms.nodes.filter(program => id !== program.mysqlId && !!program.products.find(product => product.category_id === firstProduct?.category_id)).slice(0,8)
  const { user } = useAuth0()
  const userId = user?.sub
  const reflink = (product) => {
    if(!product.reflink.startsWith('https://tc.tradetracker.net') && product.programs.platforms.param === 'r'){
      return userId
      ? product.reflink +
        `_cnp-${encodeURIComponent(
          userId || "guestcnp"
        )}`
      : product.reflink +
        `guestcnp`
    }
    return userId
                            ? product.reflink +
                              `&${
                                product.programs.platforms.param
                              }=_cnp-${encodeURIComponent(
                                userId || "guestcnp"
                              )}`
                            : product.reflink +
                              `&${product.programs.platforms.param}=guestcnp`
  }
  return (
    <Layout>
      <Seo title={name} />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        closeOnClick
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-16">
        <div className="p-4 sm:px-6 flex flex-col gap-2 lg:grid lg:grid-cols-3 lg:grid-flow-row items-center lg:justify-around bg-amber-50 mb-8 border border-amber-100 shadow-md rounded-md">
        <div className="flex justify-center lg:justify-start  order-2 lg:order-first">
          { isBrowser() && window.navigation.canGoBack && !products.every(product => product.category_id === firstProduct?.category_id) ? 
          <button className="super-btn" onClick={() => {window.navigation.back()}}><IoArrowBack className="text-lg mr-2" /> Torna indietro</button>
          :
          <Link className="super-btn" to={"/servizi/" + firstProduct?.category_url}><IoArrowBack className="text-lg mr-2" />  Torna indietro</Link>
}
        </div>
        <div className="flex items-center justify-center">
          {logo ? (
            <img
              src={
                logo
                  ? 
                  `https://clicknpay.it/static-images/programs/${logo}`
                  : defaultImage
              }
              alt={name}
              className="w-24 h-auto mr-4"
            />
          ) : (
            <p>{name}</p>
          )}
          <div className="">
            <h3 className="text-sm md:text-lg leading-none md:leading-6 font-normal md:font-medium text-gray-900 uppercase">
              {name.replace(/_CPL/g, " ")}
            </h3>
          </div>
        </div>
        <div className="hidden lg:block">
        
        </div>
        </div>
        <div className="">
          {products &&
            products.map(product => (
              <div
                key={product.mysqlId}
                className="bg-white shadow-md border overflow-hidden sm:rounded-lg mb-4"
              >
                <div className=" border-gray-200">
                  <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-base font-medium text-gray-800 uppercase">
                        SERVIZIO
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                        {product.display_name}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-base font-medium text-gray-800 uppercase">
                        Punti previsti per questa operazione
                      </dt>
                      <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                        {product.formula?.verbose
                          ? product.formula?.verbose
                          : "-"}
                      </dd>
                    </div>
                    {JSON.parse(product.json_metadata)?.map((some, i) => (
                      <div
                        key={i}
                        className={
                          "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 " +
                          (i % 2 === 0 ? "bg-white" : "bg-gray-50")
                        }
                      >
                        <dt className="text-base font-medium text-gray-800 uppercase">
                          {some.label}
                        </dt>
                        <dd className="mt-1 text-base text-gray-900 prose sm:mt-0 sm:col-span-2" dangerouslySetInnerHTML={{ __html: some.text }} />
                      </div>
                    ))}
                    <div className="bg-amber-50 flex items-center flex-col md:flex-row md:justify-around py-8">
                      <a
                        href={
                          reflink(product)
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="super-btn mb-3 md:mb-0"
                      >
                        Accedi al servizio
                      </a>
                      <button
                        onClick={() => {
                          const shareLink = { 
                            title:name,
                            text: product.display_name,
                            url: reflink(product)
                          }
                          if(navigator.canShare(shareLink)){
                            navigator.share(shareLink);
                            return;
                          }
                          navigator.clipboard.writeText(
                            shareLink.url
                          )
                          toast.success("Link copiato!")
                        }}
                        className="super-btn bg-transparent border border-sky-700 text-sky-700 hover:bg-sky-700 hover:text-white hover:shadow-md hover:shadow-sky-200"
                      >
                        Invia ad un amico
                      </button>
                    </div>
                  </dl>
                </div>
              </div>
            ))}
        </div>
      </section>
      {!!otherPrograms.length && (
      <section className="operatori py-14 bg-blue-100">
      <div className="">
        <div className="text-center">
          <p className="my-2 text-3xl leading-8 font-bold text-gradient text-center sm:text-4xl">
            Non hai trovato quello che cercavi?<br />
            <span className="text-2xl sm:text-3xl font-medium text-white drop-shadow-md">
              consulta altri servizi
            </span>
          </p>
        </div>
      <ProductSlider items={otherPrograms} />
        </div>
      </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query GetPro($mysqlId: Int) {
    mysqlPrograms(mysqlId: { eq: $mysqlId }) {
      mysqlId
      logo
      name
      products {
        json_metadata
        category_id
        category_url
        category_name
        reflink
        display
        display_name
        mysqlId
        formula {
          verbose
        }
        programs {
          platforms {
            param
          }
        }
      }
    }
    allMysqlPrograms(filter: {enabled: {eq: "1"}}) {
      nodes {
        mysqlId
        name
        logo
        enabled
        products {
          display_name
          category_id
        }
      }
    }
    }
`

export default UnicoProdotto
